import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import Product from "../components/Product"

const Categories = ({ pageContext, data }) => {
  function capitaliseFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1)
  }

  function replaceDashesWishSpaces(string) {
    return string.replace("-", " ")
  }

  const formattedCategory = replaceDashesWishSpaces(
    capitaliseFirstLetter(pageContext.category)
  )
  const description = `Engineered for Australia's extreme summers & winters. Heat or cool your home with our range of ducted heaters & evaporative coolers. Smart choice cooling. Leading warranties. Easy installation. Made to last. Types: Spilt system air cons, evaporative cooling.`
  const breadcrumbs = [
    {
      label: "Products",
      path: "/products/",
      active: false,
    },
    {
      label: formattedCategory,
      path: `/products/category/${pageContext.category}`,
      active: false,
    },
  ]

  const items = []
  const products = data.allProductsYaml.edges
  products.forEach((product) => {
    items.push(
      <Product
        data={product}
        category={product.categories}
        key={product.node.id}
      />
    )
  })

  return (
    <Layout>
      <SEO title={`${formattedCategory} products`} description={description} />

      <Breadcrumbs items={breadcrumbs} />

      <div className="uk-section uk-section-secondary">
        <div className="uk-container">
          <div className="uk-grid uk-grid-small" uk-grid="">
            <aside className="uk-width-1-4@m">
              <div
                className="uk-background-muted uk-padding-small"
                uk-sticky="media: @m; offset: 90; bottom: true;"
              >
                <h5>Find your ideal system</h5>
                <div className="category-links">
                  <p className="uk-text-bold uk-margin-small">System Type</p>
                  <ul className="uk-list uk-margin-remove">
                    <li>
                      <Link
                        to={
                          pageContext.category === "dual-function"
                            ? "/products/"
                            : "/products/category/dual-function/"
                        }
                        className={
                          pageContext.category === "dual-function"
                            ? "uk-link-reset uk-flex uk-flex-middle checked"
                            : "uk-link-reset uk-flex uk-flex-middle"
                        }
                      >
                        <div className="checkbox uk-margin-small-right">
                          <span uk-icon="icon: check; ratio: 0.8"></span>
                        </div>
                        Dual function
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          pageContext.category === "heating"
                            ? "/products/"
                            : "/products/category/heating/"
                        }
                        className={
                          pageContext.category === "heating"
                            ? "uk-link-reset uk-flex uk-flex-middle checked"
                            : "uk-link-reset uk-flex uk-flex-middle"
                        }
                      >
                        <div className="checkbox uk-margin-small-right">
                          <span uk-icon="icon: check; ratio: 0.8"></span>
                        </div>
                        Heating Only
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          pageContext.category === "cooling"
                            ? "/products/"
                            : "/products/category/cooling/"
                        }
                        className={
                          pageContext.category === "cooling"
                            ? "uk-link-reset uk-flex uk-flex-middle checked"
                            : "uk-link-reset uk-flex uk-flex-middle"
                        }
                      >
                        <div className="checkbox uk-margin-small-right">
                          <span uk-icon="icon: check; ratio: 0.8"></span>
                        </div>
                        Cooling Only
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
            <main className="uk-width-3-4@m">
              <div
                className="uk-grid uk-grid-small uk-child-width-1-3@s uk-text-center uk-grid-match"
                uk-grid=""
              >
                {items}
              </div>
            </main>
          </div>
        </div>
      </div>

      <div className="uk-section uk-section-secondary uk-section-small uk-section-default">
        <div className="uk-container">
          <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
            <small className="uk-text-meta">{description} </small>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allProductsYaml: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query ($category: String) {
    allProductsYaml(
      limit: 10
      sort: { fields: sort, order: ASC }
      filter: { categories: { in: [$category] } }
    ) {
      totalCount
      edges {
        node {
          id
          title
          description_short
          categories
          variants {
            price
          }
          featured_image
          fields {
            slug
          }
        }
      }
    }
  }
`
